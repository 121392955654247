<template>
  <div>
    <div v-if="!loading_prof" class="profile-ed-mn">
      <template v-if="userMainInfo">
        <div class="row row-12 nowrap align-items-center">
          <div class="col-auto">
            <div class="profile-ed-mn-image">
              <img v-if="imageUploadSrc" :src="imageUploadSrc" alt="image">
              <img v-else-if="userMainInfo.photo" :src="userMainInfo.photo" alt="imag">
              <img v-else src="@/assets/img/default-user-avatar.png" alt="imag">
            </div>
          </div>
          <div class="col-w-100">
            <div class="h4 lb-mb-4">Upload photo</div>
            <div class="text-md">The photo must be no more than 2 MB in size. We support JPG, PNG.</div>
          </div>
        </div>
        <div class="btn-wrapper lb-tp-15 lb-mb-20">
          <button class="btn btn-outlined" @click="triggerUpload">Upload photo</button>
          <button class="btn btn-outlined btn-icon" @click="removePhoto"><span class="icon"><img src="@/assets/img/icons/icon-trash.svg" alt="icon"></span></button>
        </div>
        <input
          style="display: none"
          @change="changeFile($event)"
          ref="fileInput"
          type="file"
          accept="image/*"
          name="imgUpload123"
        >
        <div class="form-group md lb-mb-15">
          <div class="row row-24">
            <div class="col-lg-6">
              <div
                class="input-wrapper-material focus-st"
                :class="{'typed': userMainInfo.name}"
              >
                <div class="input-inner">
                  <input
                    class="input input-material typed"
                    name="name" type="text"
                    @focus="focusInput"
                    @blur="blurInput($event); $v.userMainInfo.name.$touch()"
                    @input="typedInput"
                    v-model="userMainInfo.name"
                    :class="{'error': $v.userMainInfo.name.$error}"
                  >
                  <label>Full name</label>
                </div>
                <div v-if="$v.userMainInfo.name.$error" class="input-error">Error</div>
              </div>
            </div>
            <div class="col-lg-6">
              <div
                class="input-wrapper-material dispbled focus-st typed"
              >
                <div class="input-inner">
                  <input
                    class="input input-material typed"
                    name="name"
                    type="text"
                    value="Coach"
                  >
                  <label>Job title</label>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div
                class="ml-select-wrapper-material"
                :class="{'typed': userMainInfo.tags.length > 0}"
              >
                <multiselect
                  tag-placeholder="Add this as new tag"
                  placeholder=""
                  label="title"
                  track-by="id"
                  v-model="userMainInfo.tags"
                  :options="options_tags"
                  :multiple="true"
                >
                  <span slot="noResult">Oops! No elements found.</span>
                </multiselect>
                <label>Tags</label>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="input-wrapper-material textarea focus-st" :class="{'typed': userMainInfo.background}">
                <div class="input-inner">
                <textarea
                  class="input input-material typed"
                  @focus="focusInput"
                  @blur="blurInput($event)"
                  @input="typedInput"
                  v-model="userMainInfo.background"
                ></textarea>
                  <label>About me</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-wrapper">
          <button
            class="btn btn-primary btn-loader"
            :class="{'disable': $v.$invalid, 'loader': loading_save}"
            @click="sendBasicprofile"
          >
            Save changes
          </button>
          <router-link tag="button" to="/" class="btn btn-secondary">Cancel</router-link>
        </div>
      </template>
    </div>
    <div v-else class="loader-wrapper">
      <div class="loader-circle">
        <img src="@/assets/img/loader-icon.png" alt="loader">
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'

export default {
  data () {
    return {
      userMainInfoOld: null,
      imageUploadSrc: '',
      loading_save: false,
      loading_prof: false,
      loading_tags: true
    }
  },
  computed: {
    userMainInfo () {
      return this.$store.getters.basicprofileEdit
    },
    updatePhoto () {
      return this.$store.getters.updatePhoto
    },
    userSecurity () {
      return this.$store.getters.userSecurity
    },
    options_tags () {
      return this.$store.getters.basicprofile_tags
    },
    loading_together () {
      if (!this.loading_tags) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    focusInput: function (e) {
      e.target.closest('.input-wrapper-material').classList.add('focus')
    },
    blurInput: function (e) {
      e.target.closest('.input-wrapper-material').classList.remove('focus')
    },
    typedInput: function (e) {
      if (e.target.value) {
        e.target.closest('.input-wrapper-material').classList.add('typed')
      } else {
        e.target.closest('.input-wrapper-material').classList.remove('typed')
      }
    },
    triggerUpload: function () {
      this.$refs.fileInput.click()
    },
    changeFile: function (event) {
      const file = event.target.files[0]
      const formData = new FormData()
      formData.set('photo', file)
      this.$store.commit('updatePhoto', formData)
      const reader = new FileReader()
      reader.onload = () => {
        this.imageUploadSrc = reader.result
      }
      reader.readAsDataURL(file)
    },
    removePhoto: function () {
      this.$store.commit('updatePhoto', null)
      this.$store.commit('removePhoto')
      this.imageUploadSrc = ''
    },
    sendBasicprofile: function () {
      this.loading_save = true
      this.$store.dispatch('sendBasicprofile')
        .finally(() => {
          this.imageUploadSrc = ''
          this.loading_save = false
          this.userMainInfoOld = JSON.stringify(this.userMainInfo)
        })
    },
    fetchTogether: function () {
      if (this.options_tags.length === 0) {
        this.loading_prof = true
        this.loading_tags = true
      }
      this.$store.dispatch('fetchTags')
        .finally(() => {
          this.loading_tags = false
        })
    },
    fetchBasicprofile: function () {
      if (this.userMainInfo === '') {
        this.loading_prof = true
      }
      this.$store.dispatch('fetchBasicprofile')
        .finally(() => {
          this.loading_prof = false
          this.userMainInfoOld = JSON.stringify(this.userMainInfo)
        })
    }
  },
  mounted () {
    this.userMainInfoOld = JSON.stringify(this.userMainInfo)
    this.fetchTogether()
  },
  beforeRouteLeave (to, from, next) {
    if (JSON.stringify(this.userMainInfo) !== this.userMainInfoOld) {
      const isLeave = confirm('Are you sure you want to leave the page')
      if (isLeave) {
        next(true)
      }
    } else {
      next(true)
    }
  },
  watch: {
    loading_together: function (v) {
      if (!v) {
        this.fetchBasicprofile()
      }
    }
  },
  validations: {
    userMainInfo: {
      name: {
        required
      }
    }
  },
  components: {
    Multiselect
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.profile-ed{
  &-mn {
    padding: 24px;
    border-radius: 16px;
    background-color: #fff;
    &-image {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
  &-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title{
      margin-right: 15px;
    }
  }
}
</style>
